import { useTranslation } from "react-i18next"
import { useStore } from "@nanostores/react"

import type { LayoutDef } from "@types"
import { initAnalytics, trackPageView } from "@utils/analytics"
import { $consentBanner, closeConsentBanner } from "@stores/consentBanner"
import { openConsentSettings } from "@stores/consentSettings"

import BottomSheet from "@components/BottomSheet"
import Button from "@components/Button"

interface ConsentBannerProps {
  layout: LayoutDef
}

const ConsentBanner = (props: ConsentBannerProps): JSX.Element => {
  const { t } = useTranslation<string>("common")
  const { is_open } = useStore($consentBanner)

  const onClickAllow = () => {
    localStorage.setItem("consentAccepted", "true")
    localStorage.setItem("marketingConsentAccepted", "true")
    initAnalytics()
    trackPageView()
    closeConsentBanner()
  }

  return (
    <BottomSheet is_open={is_open} prevent_closing with_padding id="overlay-c">
      <div className="max-w-[52rem]" data-cy="overlay-c">
        <h3 className="text-24 leading-28 mb-24 font-semibold">{props.layout.cookies_title}</h3>

        <div className="leading-22">
          <div
            className="overlay-c-html"
            dangerouslySetInnerHTML={{
              __html: props.layout.cookies_copy,
            }}
          />
        </div>

        <div className="mt-32 flex justify-between md:justify-start">
          <div className="md:mr-8">
            <Button version="PRIMARY" onClick={onClickAllow} data-cy="overlay-c-accept-all">
              {t("cookies.allowAll")}
            </Button>
          </div>

          <div>
            <Button
              version="SECONDARY"
              data-cy="overlay-c-open-settings"
              onClick={() => {
                openConsentSettings()
                closeConsentBanner()
              }}
            >
              {t("cookies.settings")}
            </Button>
          </div>
        </div>
      </div>
    </BottomSheet>
  )
}

export default ConsentBanner
